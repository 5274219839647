import type { FC } from "react";
import React, { useEffect, useState } from "react";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import NineSwitch from "@9amhealth/wcl/generated/react/switch";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import Collection from "src/ui/components/StyledComponents/Collection";
import { useBloc } from "src/state/state";
import { NinePill, NineSpacer } from "@9amhealth/wcl/generated/react";
import { isHybridApp } from "src/lib/platform";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import NotificationSettingsBloc from "src/ui/components/NotificationSettings/NotificationSettingsBloc";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import AppDialog from "src/ui/components/AppDialog/AppDialog";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";

const NotificationSettings: FC = () => {
  const title = translate("notificationSettings");
  const [
    { pushNotifications, smsNotifications, showConfirmModal, loading },
    {
      toggleSmsNotifications,
      togglePushNotifications,
      hideModal,
      confirmChanges,
      openNativeSettings,
      reloadUserPreferences,
      allDisabled
    }
  ] = useBloc(NotificationSettingsBloc, {
    create: () => new NotificationSettingsBloc()
  });

  const [showWarningDialog, setShowWarningDialog] = useState(false);

  useEffect(() => {
    void reloadUserPreferences();
  }, []);

  // hide the push notifications toggle if we're not in a hybrid app
  const showPushSettings = isHybridApp();
  const showItems = showPushSettings ? 2 : 1;

  return (
    <>
      <OverlayBackButton show fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={title}></AppPageTitle>
      <NineSpacer s="xl" />

      {showConfirmModal === "pushNotifications" && (
        <AppDialog
          confirmText={"enable.feature"}
          confirmArrow={false}
          onCancel={hideModal}
          onConfirm={openNativeSettings}
        >
          <h5>
            <Translate msg="notification.turnOn" />
          </h5>
          <p>
            <Translate msg="notification.turnOn.description" />
          </p>
        </AppDialog>
      )}
      {showConfirmModal === "disableAll" && (
        <AppDialog
          confirmText={"iUnderstand"}
          confirmArrow={false}
          onCancel={hideModal}
          onConfirm={confirmChanges}
        >
          <h5>
            <Translate msg="notification.careTeam.title" />
          </h5>
          <p>
            <Translate msg="notification.careTeam.description" />
          </p>
        </AppDialog>
      )}

      {showWarningDialog && (
        <AppDialog
          confirmText={"iUnderstand"}
          confirmArrow={false}
          onConfirm={() => setShowWarningDialog(false)}
          onClose={() => setShowWarningDialog(false)}
        >
          <h5>
            <Translate msg="notification.careTeam.title" />
          </h5>
          <p>
            <Translate msg="notification.careTeam.description" />
          </p>
        </AppDialog>
      )}

      <p style={{ marginBottom: "0.4em", marginTop: 0, fontWeight: "500" }}>
        <Translate msg="notification.careTeam.messages" />{" "}
        {allDisabled && (
          <NinePill
            onClick={() => setShowWarningDialog(true)}
            style={{
              backgroundColor: "var(--color-sunrise-orange)",
              marginLeft: "0.6rem"
            }}
          >
            ⚠️ <Translate msg="warning" />
          </NinePill>
        )}
      </p>
      <AsyncContent check={[!loading]} height={`${showItems * 5}rem`}>
        <Collection>
          {showPushSettings && (
            <CollectionItemLink
              label={translate("notification.device")}
              onClick={togglePushNotifications}
              action={<NineSwitch checked={pushNotifications} />}
            />
          )}
          <CollectionItemLink
            label={translate("notification.sms")}
            onClick={toggleSmsNotifications}
            action={<NineSwitch checked={smsNotifications} />}
          />
        </Collection>
      </AsyncContent>
      <p>
        <Translate msg="notification.careTeam.keepInTouch" />
      </p>
    </>
  );
};

export default NotificationSettings;
