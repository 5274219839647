import type { FC } from "react";
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { IconCrossXCloseMissed } from "src/constants/icons";
import { NineActions, NineButton } from "@9amhealth/wcl/generated/react";
import Translate from "src/ui/components/Translate/Translate";
import { keyframes } from "@emotion/css";
import { appStatusBarState } from "src/state/state";
import { createPortal } from "react-dom";
import reportErrorSentry from "src/lib/reportErrorSentry";
import type { TranslationKey } from "src/types/translationKey";

const showWrapKeyframes = keyframes`
    from {
        opacity: 0;
      
    }
    to {
        opacity: 1;
    }
`;

const showContentKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Wrap = styled.dialog`
  label: AppDialog;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: grid;
  place-items: center;
  border: none;
  padding: calc(var(--ion-safe-area-top, 0) + 2rem) 2rem;
  outline: none;
  animation: ${showWrapKeyframes} 0.25s ease-in-out;
`;

const Content = styled.div`
  background-color: var(--color-cream, #fff);
  padding: 1.5rem 1rem 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--light-shadow);
  min-width: 120px;
  position: relative;
  max-width: min(calc(100vw - 2rem), 500px);
  animation: ${showContentKeyframes} 0.25s ease-in-out;

  h5 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }

  p {
    margin: 0.3rem 0 1rem;
    font-weight: 400;
  }

  nine-actions {
    padding-left: 2rem;
    padding-top: 1rem;
    width: 100%;
    justify-content: flex-end;
  }
`;

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background-color: transparent;
  border: none;

  svg {
    width: 1.5rem;
    height: auto;
  }
`;

const AppDialog: FC<{
  children: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  confirmText?: TranslationKey;
  cancelText?: TranslationKey;
  confirmArrow?: boolean;
}> = ({ children, onCancel, onConfirm, onClose, confirmArrow, ...props }) => {
  const { confirmText, cancelText } = props;

  const [render, setRender] = React.useState(true);
  useEffect(() => {
    return () => {
      setRender(false);
      onClose?.();
      appStatusBarState.resetThemeDefault();
    };
  }, []);

  if (!render) {
    return null;
  }

  const target = document.getElementById("appDialogTarget");
  if (!target) {
    reportErrorSentry("AppDialog: target not found");
    return null;
  }

  return createPortal(
    <Wrap>
      <Content>
        <Close
          onClick={() => {
            setRender(false);
            onClose?.();
            onCancel?.();
          }}
        >
          <IconCrossXCloseMissed />
        </Close>
        {children}
        <NineActions>
          {onCancel && (
            <NineButton
              variant="ghost"
              arrow=""
              padding="equal"
              onClick={() => {
                onCancel();
                onClose?.();
              }}
            >
              {cancelText ? (
                <Translate msg={cancelText} />
              ) : (
                <Translate msg="cancel" />
              )}
            </NineButton>
          )}
          {onConfirm && (
            <NineButton
              variant="fill"
              arrow={confirmArrow ? ">" : ""}
              color="black"
              padding="equal"
              onClick={() => {
                onConfirm();
                onClose?.();
              }}
            >
              {confirmText ? (
                <Translate msg={confirmText} />
              ) : (
                <Translate msg="confirm" />
              )}
            </NineButton>
          )}
        </NineActions>
      </Content>
    </Wrap>,
    target
  );
};

export default AppDialog;
