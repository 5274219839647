import { Cubit } from "blac";
import { pushNotificationState, userPreferences } from "src/state/state";
import type { NativeSettingsPlugin } from "capacitor-native-settings";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings
} from "capacitor-native-settings";
import type UserPreferencesCubit from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import type { UserPreferences } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import type PushNotificationsBloc from "src/hybrid/components/PushNotificationsControls";
import { isHybridApp } from "src/lib/platform";

export interface NotificationSettingsState {
  pushNotifications: boolean;
  smsNotifications: boolean;
  showConfirmModal?: "disableAll" | "pushNotifications";
  loading?: boolean;
}

export default class NotificationSettingsBloc extends Cubit<NotificationSettingsState> {
  stateAfterConfirm: NotificationSettingsState | null = null;
  nativeSettings: NativeSettingsPlugin;
  userPrefController: UserPreferencesCubit;
  pushController: PushNotificationsBloc;
  deviceHasPush: boolean;

  constructor(
    params: {
      userPrefController?: UserPreferencesCubit;
      pushController?: PushNotificationsBloc;
      deviceHasPush?: boolean;
      nativeSettingsController?: NativeSettingsPlugin;
    } = {}
  ) {
    const {
      userPrefController = userPreferences,
      pushController = pushNotificationState,
      deviceHasPush = isHybridApp(),
      nativeSettingsController = NativeSettings
    } = params;
    super({
      pushNotifications: userPrefController.pushNotificationsCareTeamEnabled,
      smsNotifications: userPrefController.smsNotificationsCareTeamEnabled,
      loading: false
    });

    this.userPrefController = userPrefController;
    this.pushController = pushController;
    this.deviceHasPush = deviceHasPush;
    this.nativeSettings = nativeSettingsController;

    void this.checkDeviceSettings();
  }

  reloadUserPreferences = async (): Promise<void> => {
    this.emit({ ...this.state, loading: true });
    await this.userPrefController.loadUserPreferences();
    const pushPermissions = await this.pushController.checkPermissions();
    const pushGranted = pushPermissions.receive === "granted";
    const pushNotifications =
      this.deviceHasPush &&
      pushGranted &&
      this.userPrefController.pushNotificationsCareTeamEnabled;

    this.emit({
      pushNotifications,
      smsNotifications: this.userPrefController.smsNotificationsCareTeamEnabled,
      loading: false
    });
  };

  checkDeviceSettings = async (): Promise<void> => {
    if (this.deviceHasPush) {
      const pushPermissions = await this.pushController.checkPermissions();
      const pushNotGranted = pushPermissions.receive !== "granted";
      if (pushNotGranted) {
        this.emit({
          ...this.state,
          pushNotifications: false
        });
      }
    }
  };

  saveUserPreferences = async (): Promise<void> => {
    const newPreferences: Partial<UserPreferences> = {
      [UserPreferenceKeys.smsNotificationsCareTeam]: this.state.smsNotifications
    };

    // only save push notifications if we're in a hybrid app
    if (this.deviceHasPush) {
      newPreferences[UserPreferenceKeys.pushNotificationsCareTeam] =
        this.state.pushNotifications;
    }

    await this.userPrefController.updateUserPreferences(newPreferences);
  };

  validateChanges = async (
    futureState: NotificationSettingsState
  ): Promise<boolean> => {
    // always consider push disabled, if we are not on a hybrid app
    const pushDisabled = !futureState.pushNotifications || !this.deviceHasPush;

    const allDisabled = pushDisabled && !futureState.smsNotifications;

    if (allDisabled) {
      this.stateAfterConfirm = futureState;
      this.showModal("disableAll");
      return false;
    }

    return this.validatePushSettings(futureState);
  };

  validatePushSettings = async (
    futureState: NotificationSettingsState
  ): Promise<boolean> => {
    if (!this.deviceHasPush) {
      return true;
    }

    if (futureState.pushNotifications && !this.state.pushNotifications) {
      const permissions = await this.pushController.checkPermissions();

      //   handle prompt
      if (permissions.receive === "prompt") {
        await this.pushController.requestPermissions();
        // validate again after requesting permissions, since the user may have denied
        return this.validatePushSettings(futureState);
      }

      //   handle denied or prompt-with-rationale
      if (
        permissions.receive === "denied" ||
        permissions.receive === "prompt-with-rationale"
      ) {
        this.stateAfterConfirm = futureState;
        this.showModal("pushNotifications");
        return false;
      }
    }

    return true;
  };

  confirmChanges = (): void => {
    if (!this.stateAfterConfirm) {
      return;
    }

    this.emit(this.stateAfterConfirm);
    this.hideModal();
    void this.saveUserPreferences();
  };

  togglePushNotifications = (set?: boolean): void => {
    const futureState: NotificationSettingsState = {
      ...this.state,
      pushNotifications: set ?? !this.state.pushNotifications
    };

    void this.validateAndSave(futureState);
  };

  toggleSmsNotifications = (): void => {
    const futureState: NotificationSettingsState = {
      ...this.state,
      smsNotifications: !this.state.smsNotifications
    };

    void this.validateAndSave(futureState);
  };

  validateAndSave = async (
    futureState: NotificationSettingsState
  ): Promise<void> => {
    const valid = await this.validateChanges(futureState);
    if (!valid) {
      return;
    }
    this.emit(futureState);
    void this.saveUserPreferences();
  };

  hideModal = (): void => {
    this.emit({
      ...this.state,
      showConfirmModal: undefined
    });
    this.stateAfterConfirm = null;
  };

  showModal = (name: NotificationSettingsState["showConfirmModal"]): void => {
    this.emit({
      ...this.state,
      showConfirmModal: name
    });
  };

  openNativeSettings = (): void => {
    if (!this.deviceHasPush) {
      return;
    }

    void this.nativeSettings.open({
      optionAndroid: AndroidSettings.AppNotification,
      optionIOS: IOSSettings.App
    });

    this.hideModal();
  };

  get allDisabled(): boolean {
    return !this.state.pushNotifications && !this.state.smsNotifications;
  }
}
